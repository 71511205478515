import {
  AfterViewInit,
  ApplicationRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
  output,
  OutputEmitterRef,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { WINDOW } from '@owain/tokens/window.provider';
import { injectNetwork } from 'ngxtension/inject-network';
import { filter, takeWhile, tap } from 'rxjs/operators';

const observerSupported = (window: Window | null): boolean =>
  typeof window !== 'undefined' ? !!(window as any).IntersectionObserver : false;
const idleCallbackSupported = (window: Window | null): boolean =>
  typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

@Directive({
  selector: '[prefetch]',
})
export class PrefetchDirective implements OnInit, AfterViewInit, OnDestroy {
  public prefetchMode: InputSignal<('load' | 'hover' | 'visible')[]> = input<('load' | 'hover' | 'visible')[]>([
    'hover',
    'visible',
  ]);
  public prefetch: OutputEmitterRef<void> = output<void>();

  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly window: Window = inject(WINDOW);
  private readonly platformId: Object = inject(PLATFORM_ID);
  private readonly network = injectNetwork();

  private readonly observerSupported: boolean = observerSupported(this.window);
  private readonly idleCallbackSupported: boolean = idleCallbackSupported(this.window);

  private loaded = false;

  private idleCallbackId: number | undefined;
  private observer: IntersectionObserver | null | undefined;
  private subscriptionDone: boolean = false;

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.prefetchMode().includes('load')) {
      this.prefetchIdle();
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (!this.prefetchMode().includes('visible')) {
      return;
    }

    if (!this.observerSupported) {
      this.prefetchIdle();

      return;
    }

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (this.observer) {
            this.observer.disconnect();
          }

          this.prefetchIdle();
        }
      });
    });

    if (!this.observer) {
      return;
    }

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.idleCallbackId !== undefined) {
      this.window.cancelIdleCallback(this.idleCallbackId);
    }
  }

  public prefetchData(): void {
    if (this.loaded) {
      return;
    }

    this.loaded = true;

    if (this.network.supported()) {
      if (this.network.saveData() || (this.network.effectiveType() || '').includes('2g')) {
        return;
      }
    }

    this.applicationRef.isStable
      .pipe(
        takeWhile(() => !this.subscriptionDone),
        filter(isStable => isStable),
        tap(() => {
          this.subscriptionDone = true;

          this.idleCallbackSupported
            ? (this.idleCallbackId = this.window.requestIdleCallback(() => {
                this.prefetch.emit();
              }))
            : this.prefetch.emit();
        })
      )
      .subscribe();
  }

  private prefetchIdle(): void {
    this.idleCallbackSupported
      ? (this.idleCallbackId = this.window.requestIdleCallback(() => {
          this.prefetchData();
        }))
      : this.prefetchData();
  }

  @HostListener('mouseenter')
  private onMouseEnter(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.prefetchMode().includes('hover')) {
      this.prefetchIdle();
    }
  }
}
